import moment from 'moment-mini'
import { CURRENCY_SHORT_CODES, FIAT_CURRENCY_NAMES } from '@/constants/auto-exchange'
import { DEAL_STATUS_LABEL, FORM_TYPE_NAMES } from '@/constants/P2P'
import Decimal from 'decimal.js'

export default class {
  constructor (data) {
    const { match: {
      operation_id,
      offer_operation_id,
      timestamp,
      user_account_info,
      status,
      participant_user_id,
      matched_banknotes,
      matched_fiat_types,
      owned_amount,
      owned_reserve_amount,
      owned_documents,
      wanted_amount,
      wanted_reserve_amount,
      wanted_documents,
      documents,
    }, owned_requisites, wanted_requisites } = data || { match: {}, owned_requisites: [], wanted_requisites: [] }
    this.operation_id = operation_id
    this.offer_operation_id = offer_operation_id
    this.timestamp = timestamp
    this.user_account_info = user_account_info
    this.status = status
    this.participant_user_id = participant_user_id
    this.matched_banknotes = matched_banknotes
    this.matched_fiat_types = matched_fiat_types
    this.documents = documents
    this.owned_amount = owned_amount.amount
    this.owned_currency = CURRENCY_SHORT_CODES[owned_amount.currency]
    this.owned_reserve = owned_reserve_amount
    this.owned_documents = owned_documents
    this.wanted_amount = wanted_amount.amount
    this.wanted_currency = CURRENCY_SHORT_CODES[wanted_amount.currency]
    this.wanted_reserve = wanted_reserve_amount
    this.wanted_documents = wanted_documents
    this._time = moment.unix(this.timestamp).format('DD/MM/YY HH:mm')
    this.isSelling = !!FIAT_CURRENCY_NAMES[this.owned_currency]
    this.internalPrefix = this.isSelling ? 'wanted' : 'owned'
    this.externalPrefix = this.isSelling ? 'owned' : 'wanted'
    this.internalAmount = this[`${this.internalPrefix}_amount`]
    this.internalCurrency = this[`${this.internalPrefix}_currency`]
    this.externalAmount = this[`${this.externalPrefix}_amount`]
    this.externalCurrency = this[`${this.externalPrefix}_currency`]
    this.operationWithExternalFiat = [this.owned_currency, this.wanted_currency].some(i => !!FIAT_CURRENCY_NAMES[i])
    this.userId = this.user_account_info.user_id
    this.statusText = DEAL_STATUS_LABEL[this.status] || ''
    this.exchange_rate = new Decimal(this.externalAmount || 0).div(this.internalAmount || 1).toFixed(2)
    this.requisites = this.isSelling ? owned_requisites : wanted_requisites
    this.owned_requisites = owned_requisites
    this.wanted_requisites = wanted_requisites
  }

}
